import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import LuteinComplexMobile from "./../../images/lutein-spasim-zrenieto-header.jpg";
import LuteinComplexTablet from "./../../images/lutein-spasim-zrenieto-header.jpg";
import LuteinComplexDesktop from "./../../images/lutein-spasim-zrenieto-header.jpg";
import LuteinComplexXL from "./../../images/lutein-spasim-zrenieto-header.jpg";

import lutShield from "./../../images/lutein-spasim-eye-bisect.jpg";
import warningSign from "./../../images/lut-virusi-warning-sign.svg";
import no1 from "./../../images/lutein-no1-stamp.svg";

import lutein from "./../../images/lutein-wet-wipe-promo.jpg";

const LuteinSpasim = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-spasim" }}>
    <Seo title="Как да си спасим зрението?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>КАК ДА СИ СПАСИМ ЗРЕНИЕТО?</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Как да си спасим зрението?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Как да си спасим зрението?
          </h1>
          <h2 className="font-light ">
            <em>Активен „еликсир“ за очи подсигурява всичко необходимо</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            „Очите са по-точни свидетели, отколкото ушите“ са казали древните
            гърци. Видели със собствените си очи истинско спасение за зрението
            си, препатили и офтал-молози са единодушни: работи. Предпочитана
            заради безспорния си ефект, доказан не в реклами, а на живо,
            специалната френска разработка Lutein Complex Plus няма аналог у нас
            поради уникалната си формула. Действаща като своеобразен еликсир, тя
            успешно „рестартира“ зрението на българите повече от 15 години, като
            подсигурява всичко необходимо за очите.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Защо работи по-добре според науката?</h4>
          <p>
            Изправени пред научния факт, че веществата на прах се разграждат от
            тялото много по-трудно, а тези във вода пък твърде бързо се
            изхвърлят от него, специалистите решават да разтворят предварително
            ценните съставки на ЛУТЕИН КОМПЛЕКС ПЛЮС в маслена среда. Така,
            облягайки се на простичкия факт, че олията покриват плътно всичко,
            до което се докоснат и текат бавно, те успешно повишават КПД-то на
            дозата, за да бъде тя усвоена максимално пълноценно. Друго научно
            постижение, което отличава ЛУТЕИН КОМПЛЕКС ПЛЮС, е патентованата
            технология на производство LICAPS, при която течните екстракти са
            вакуумно запечатани в капсула, така че да нямат никакъв досег с
            атмосферата, а така полезните качества остават изцяло активни до
            последния момент.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={{ span: 4, offset: 0 }} xs={{ span: 8, offset: 2 }}>
          <Image className="w-100 mb-4 float-left" src={lutShield} />
        </Col>
        <Col sm={8} xs={12}>
          <h4>ОЧЕвидните ефекти</h4>

          <p>
            Парене, сухота, замъглено виждане, „играещи“ букви – тези и много
            други симптоми са добре познати на хората с проблемни очи. Подобни
            оплаквания са сред „мишените“, които{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> „уцелва в
            десетката“. Наблюденията сочат, че само с една капсула на ден за три
            месеца с негова помощ зрението става по-остро и ясно, независимо от
            възрастта. Експертно подбраните съставки (лутеин, зеаксантин,
            екстракт от черна боровинка, витамини С, Е и цинк) усилват
            действието си, благодарение на масления разтвор. Така, предпочитан и
            по-добре разпознат от тялото ни, разтворът разгръща най-добре
            способността си да вдигне завесата пред очите ни.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Фаворитът на българите</h4>
          <Image
            width={100}
            height={100}
            className="float-right ml-4"
            src={no1}
          />
          <p>
            Опитали какви ли не начини да подсилят очите си, препатилите са
            единодушни какво помага. Вече 16 години{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> е
            най-търсеният и купуван „еликсир“ за очи у нас, потвърждава
            специализирано пазарно проучване на американската мултинационална
            компания IQVIA. Един наистина прекрасен пример, че реалните ефекти
            върху здравето на потърпевшите са истинското доказателство за
            качество.
          </p>
        </Col>
        {/* <Col sm={2} xs={3}>
          <Image className="w-100 float-right" src={no1} />
        </Col> */}
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            <em>
              *Най-продаван продукт в категория „Витамини и добавки за очи“ за
              2020 г. по данни на IQVIA.
            </em>
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col className="alert alert-warning">
          <Image
            style={{
              margin: `1rem`,
              width: `80px`,
              float: `left`,
            }}
            src={warningSign}
          />
          <h3 className="warning-text">
            <strong>Катарактата води до слепота</strong>
          </h3>
          <h4>
            <em>Три малко познати факти за катарактата</em>
          </h4>

          <p className="warning-text">
            <strong>Пелената не е пред окото</strong>
            <br />
            Когато чуят „перде на очите“, както е популярното наименование на
            катаракта, болшинството от хората си представят, че пред органите на
            зрението има завеса. В действителност обаче истината е друга!
            Пердето не се образува пред, а вътре в самото око.
          </p>
          <p className="warning-text">
            <strong>Може да е засегнато само едното око</strong>
            <br />
            Противно на широкото схващане, че катаракта засяга и двете очи, не
            са редки случаите, в които помътняване на лещата се наблюдава само в
            едно око.
          </p>
          <p className="warning-text">
            <strong>Възрастта не е определяща</strong>
            <br />
            Друго, което не е толкова известно за катаракта, е, че все повече се
            подмладява. Ако преди очното заболяване е било „привилегия“ на
            възрастните, днес то се открива сред все повече млади хора.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Вземете сега ЛУТЕИН КОМПЛЕКС ПЛЮС с подарък мокри кърпички за очила,
            в най-близката до вас аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default LuteinSpasim;
